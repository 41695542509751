import React from "react"
// import { Link } from "gatsby"
import {
  Section,
  Container,
  Column,
  Columns,
  Field,
  Control,
  Button,
  Label,
  Input,
  Content,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import numerologiaData from "../data/numerologia/numerologia.json"
import { Adsense } from "../components/adsense"


export default class Numerologia extends React.Component {
  
  state = {
    date: "",
    name: "",
    numbers:{
      "personalidade":"",
      "espiritualidade":"",
      "caráter":"",
      "destino":"",
      "poder":""
    }

  }

  handleChange = event => {
    console.log(event)
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({ 
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    let { date, name, numbers } = this.state;
    name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    numbers["personalidade"]= this.calculateString(this.consonants(name));  
    numbers["espiritualidade"] = this.calculateString(this.vowels(name));    
    numbers["caráter"] = this.calculateString(name);  
    numbers["destino"] = this.calculateString(date)  
    numbers["poder"] = this.calculateString(String(numbers["caráter"]) + String(numbers["destino"]));

    this.setState({numbers:numbers})
  }

  render() {
    const path = this.props.location?.pathname || '';
    const {numbers} = this.state;
    var items = Object.keys(numbers).map(function(id, i){
      if(numbers[id]){
        const text = numerologiaData[id][numbers[id]]
        const title = "Seu número de "+ id +": "+ numbers[id]
        return (
          <Content key={i}>
            <h3 key={i}>{title}</h3>
            <p >{text}</p>
            <Adsense path={path} />
          </Content>
        )
      }else{
        return ""
      }
    });
 
    return (
      <Layout path={path}>
        <SEO title="Numerologia - Estrelaguia Guia" />
          <Container>
            <Columns>
              <Column>
              <Content>
                <h1>Numerologia</h1>
                <h2>O que é</h2>
                <p>A Numerologia Cabalística é uma das ciências esotéricas mais antigas que existe. Com a Numerologia, você pode descobrir mais sobre sua vida pessoal, amorosa e financeira, utilizando as letras de seu nome e sua data de nascimento. Para saber mais sobre sua personalidade, preencha seu nome completo e data de nascimento nos campos ao lado.</p>
                <form className=" content box" onSubmit={this.handleSubmit}>
                  <h4>Descubra mais sobre sua personalidade</h4>
                  <Field>
                      <Label>Nome</Label>
                      <Control>
                          <Input required type="text" name="name" value={this.state.name} onChange={this.handleChange}/>
                      </Control>
                  </Field>
                  <Field>
                      <Label>Data de nascimento</Label>
                      <Control>
                          <Input required type="date" name="date" value={this.state.date} onChange={this.handleChange} />
                      </Control>
                  </Field>
                  <Field>
                    <Control>
                          <Button type="submit">Submit</Button>
                      </Control>
                  </Field>
                </form>
                {items}
                </Content>
              </Column>
            </Columns>
          </Container>
      </Layout>
    )
  }
  calculateString(str) {
    if (!str || str === '') {
      return -1;
    }

    function isCoreValue(n) {
      return (n >= 1 && n <= 9) || n === 11 || n === 22;
    }

    function sumCharacters(str) {
      return Array.prototype.map.call((str || '').toLowerCase(), function (ch) {
        if (ch >= 'a' && ch <= 'z') {
          return Number(ch.charCodeAt(0) - 'a'.charCodeAt(0)) + 1;
        }
        return (ch >= '0' && ch <= '9') ? Number(ch) : 0;
      }).reduce(function (sum, current) {
        return sum + current;
      }, 0);
    }

    function calculateCore(str) {
        var sumName = sumCharacters(str);
        if (sumName === 0) {
          return -1;
        }
        return isCoreValue(sumName) ? sumName : calculateCore(String(sumName));
      }
      return calculateCore(str);
  };

  consonants(str) {
    return str.replace(/[aeiouy]/gi, '');
  }

  vowels(str) {
    return str.replace(/[bcdfghjklmnpqrstvwxz]/gi, '');
  }
}


